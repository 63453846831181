import React, { useEffect, useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'
import { useContractFunction, useEthers } from "@usedapp/core";
import { utils, ethers } from 'ethers'
import { Container, Row, Col } from "react-bootstrap";
import Button from './Button'
import TokenCard from './TokenCard';

import {handleEventUpdate, MAX_APPROVE, getGasEstimation} from '../plugins/utils'
import {stakingAbi} from '../assets/blockchain/Staking'
import {erc20Abi} from '../assets/blockchain/ERC20'
import {Config} from '../config'
import { NotificationManager } from 'react-notifications';
import { stakingAddress } from '../plugins/web3';

const provider = ethers.getDefaultProvider(Config.infuraUri);

const StakingAbi = new utils.Interface(stakingAbi); 
const ERC20Abi = new utils.Interface(erc20Abi); 
const StakingContract = new Contract(Config.stakingAddress, StakingAbi, provider);
const LoveContract = new Contract(Config.loveAddress, ERC20Abi, provider);

const RerollScreen = ({
  userTokens,
  accumulated,
  loveBalance,
  isApproved,
  setCurrentStep,
  getData,
  account
}) => {
  const { state: rerollState, send: reroll } = useContractFunction(StakingContract, 'reroll', { transactionName: 'reroll' });
  const { state: upgradeState, send: upgrade } = useContractFunction(StakingContract, 'upgrade', { transactionName: 'upgrade' });
  const { state: approveState, send: approve } = useContractFunction(LoveContract, 'approve', { transactionName: 'approve' });

  const buttonText = (action) => {
    if (action === `reroll`) {
      if (accumulated < Config.REROLL_PRICE) return isApproved ? "Reroll" : "Approve";
      return "Reroll"
    }
    if (action === `upgrade`) {
      if (accumulated < Config.UPGRADE_PRICE) return isApproved ? "Upgrade" : "Approve";
      return "Upgrade"
    } 
  }

  const triggerReroll = async (token) => {
    let payWithAccumulated = true;
    if (token.availableAction === `reroll`) {
      if (accumulated <= Config.REROLL_PRICE && loveBalance < Config.REROLL_PRICE) {
        NotificationManager.warning(`Insufficient balance for reroll!`)
        return;
      }
      if (accumulated < Config.REROLL_PRICE) {
        payWithAccumulated = false;
        if (!isApproved) {
          approve(stakingAddress, MAX_APPROVE)
          return
        }
      }
      const gas = await getGasEstimation(StakingContract.estimateGas.reroll, [token.id, payWithAccumulated], account)
      if (gas !== null) {
        reroll(token.id, payWithAccumulated, {gasLimit: gas});
        return;
      }
      reroll(token.id, payWithAccumulated);
      return;
    }

    if (accumulated <= Config.UPGRADE_PRICE && loveBalance < Config.UPGRADE_PRICE) {
      NotificationManager.warning(`Insufficient balance for reroll!`)
      return;
    }
    if (accumulated < Config.UPGRADE_PRICE) {
      payWithAccumulated = false;
        if (!isApproved) {
          approve(stakingAddress, MAX_APPROVE)
          return
        }
    }
    const gas = await getGasEstimation(StakingContract.estimateGas.upgrade, [token.id, payWithAccumulated], account)
    if (gas !== null) {
      upgrade(token.id, payWithAccumulated, {gasLimit: gas});
      return;
    }
    upgrade(token.id, payWithAccumulated);
    return;
  }

  useEffect(() => {
    handleEventUpdate(`reroll`, rerollState, () => {}, getData)
  }, [rerollState])
  useEffect(() => {
    handleEventUpdate(`upgrade`, upgradeState, () => {}, getData)
  }, [upgradeState])
  useEffect(() => {
    handleEventUpdate(`approve`, approveState, () => {}, getData)
  }, [approveState])

  return (
    <Container>
      <Col md={{offset: 2, span: 8}} xs={{offset: 0, span: 12}}>
      <Button width='250px' className={"mb-20"} onClick={() => setCurrentStep(`home`)}>Go Back</Button>
      <Row>
        <Row style={{alignItems: `center`}}>
          <Col className="white-title">MetroPasses</Col>
        </Row>
        <Row className='mobile-center'>
          {
            userTokens.staked.metroPasses.length ? (
              <>
                {userTokens.staked.metroPasses.map(x => {
                  return (
                    <Col md="3" xs="6" className="mb-20">
                      <TokenCard
                        img={`https://sea-lion-app-2tf22.ondigitalocean.app/metro-pass/${x.id}`}
                      />
                      <Row><Col className="text__normal" style={{color: `#FFF500`}}>X {(x.currentYield / Config.DIVIDER).toFixed(2)}</Col></Row>
                      <Row><Col><Button width="80%" onClick={() => triggerReroll(x)}>{buttonText(x.availableAction)}</Button></Col></Row>
                    </Col>
                  )
                })}
              </>
            ) : <div className='text__normal mb-20'>No MetroPasses Staked</div>
          }
          
        </Row>
        <Col xs={12}><div className='text__normal mb-20'>Re-rolling costs 750 $LOVE - it can increase your NFT multiplier by 0.05</div></Col>
        <Col xs={12}><div className='text__normal mb-20'>Upgrading costs 1500 $LOVE - this will increases your NFT tier</div></Col>
        </Row>
      </Col>
    </Container>
  )
}

export default RerollScreen