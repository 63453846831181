import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Button from './Button'

const HomeActive = ({
  setCurrentStep,
  accumulatedAmount,
  staked,
  unstaked,
  claim
}) => {
  return (
    <Container>
        <Container className="mb-40">
          <Row style={{alignItems: `center`, textAlign: `center`}}>
            <Col className="white-title mb-20">Accumulated amount: {accumulatedAmount} $LOVE</Col>
          </Row>
          <Row style={{alignItems: `center`, textAlign: `center`}}>
            <Col className="justify-content-center"><Button width="200px" background="#fff" onClick={claim}>Claim</Button></Col>
          </Row>
        </Container>
        <Row style={{alignItems: `center`}}> 
          <Col md={{offset: 2, span: 8}} xs={{offset: 0, span: 12}}>
            <Row>
              <Col md={{ span: 4 }} xs={{ span: 12 }} className="mobile-center">
                <Row className="text__normal mb-20 mobile-center">Staked Tokens: {staked}</Row>
                <Row><Button onClick={() => setCurrentStep(`stake`)} className="home-button">Deposit</Button></Row>
              </Col>
              <Col md={{ span: 4 }} xs={{ span: 12 }}>
                <Row className="text__normal mb-20 mobile-center">Unstaked Tokens: {unstaked}</Row>
                <Row><Button className="home-button" onClick={() => setCurrentStep(`reroll`)}>REROLL/UPGRADE</Button></Row>
              </Col>
              <Col md={{ span: 4 }} xs={{ span: 12 }} style={{justifyContent: `flexend`}}>
                <Row className="text__normal mb-20" style={{color: `#000`}}>.</Row>
                <Row style={{justifyContent: `end`}}><Button className="home-button" onClick={() => setCurrentStep(`unstake`)}>UNSTAKE</Button></Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
  )
}

export default HomeActive