import Web3 from 'web3';
import {erc721Abi} from '../assets/blockchain/ERC721';
import {erc20Abi} from '../assets/blockchain/ERC20';
import {stakingAbi} from '../assets/blockchain/Staking';

import {Config} from "../config";

export const web3 = new Web3(window.ethereum || Config.infuraUri);

export const metroPassAddress = Config.metroPassAddress;
export const passportAddress = Config.passportAddress;
export const ajAddress = Config.ajAddress;
export const loveAddress = Config.loveAddress;
export const stakingAddress = Config.stakingAddress;

const MetroPass = new web3.eth.Contract(erc721Abi, metroPassAddress);
const Passport = new web3.eth.Contract(erc721Abi, passportAddress);
const AJ = new web3.eth.Contract(erc721Abi, ajAddress);
const Love = new web3.eth.Contract(erc20Abi, loveAddress);
const Staking = new web3.eth.Contract(stakingAbi, stakingAddress);

const toDecimals = (number) => {
  return Math.floor(number * 100) / 100;
}
export const getLoveBalance = async (address) => {
  const balance = await Love.methods.balanceOf(address).call();
  return toDecimals(Number(web3.utils.fromWei(balance)))
}

export const getLoveAccumulated = async (address) => {
  const accumulated = await Staking.methods.getAccumulatedAmount(address).call();
  return toDecimals(Number(web3.utils.fromWei(accumulated)))
}

export const getIfLoveApproved = async (address) => {
  const allowance = await Love.methods.allowance(address, stakingAddress).call();
  return Number(web3.utils.fromWei(allowance)) > 10000;
}

export const getMetroPassApproved = async (account) => {
  return await MetroPass.methods.isApprovedForAll(account, stakingAddress).call();
}

export const getPassportApproved = async (account) => {
  return await Passport.methods.isApprovedForAll(account, stakingAddress).call();
}

export const getAjApproved = async (account) => {
  return await AJ.methods.isApprovedForAll(account, stakingAddress).call();
}

