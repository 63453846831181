import { useState, useEffect } from "react";
import {Config} from "../config";
import { useContractFunction, useEthers } from "@usedapp/core";
import { utils, ethers } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { erc721Abi } from '../assets/blockchain/ERC721';
import { stakingAbi } from '../assets/blockchain/Staking';
import { USER_DATA } from '../plugins/model';
import { getLoveBalance, getLoveAccumulated, getMetroPassApproved, getPassportApproved, getAjApproved, getIfLoveApproved } from '../plugins/web3';
import { canUpgradeOrReroll, handleEventUpdate, getGasEstimation } from '../plugins/utils';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useQuery } from "@apollo/client";

import HomeActive from "./HomeActive";
import StatsScreen from "./StatsScreen";
import LockedScreen from "./LockedScreen";
import Header from "./Header";
import TokensList from "./TokensList";
import RerollScreen from "./RerollScreen";

const provider = ethers.getDefaultProvider(Config.infuraUri);
const passAbi = new utils.Interface(erc721Abi); 
const metroPassContract = new Contract(Config.metroPassAddress, passAbi);
const StakingAbi = new utils.Interface(stakingAbi); 
const StakingContract = new Contract(Config.stakingAddress, StakingAbi, provider);

export default function MintPage() {
  const [loveBalance, setLoveBalance] = useState(0);
  const [dailyYield, setDailyYield] = useState(0);
  const [accumulatedAmount, setAccumulatedAmount] = useState(0);
  const [staked, setStaked] = useState(0);
  const [unstaked, setUnstaked] = useState(0);
  const [activeCollection, setActiveCollection] = useState('');
  const [userTokens, setUserTokens] = useState({
    staked: {
      metroPasses: [],
      passports: [],
      abnormalJeans: []
    },
    unstaked: {
      metroPasses: [],
      passports: [],
      abnormalJeans: []
    },
  });
  const [currentStep, setCurrentStep] = useState(`home`);
  const [locked, setLocked] = useState(true);
  const [metroPassApproved, setMetroPassApproved] = useState(false);
  const [passportApproved, setPassportApproved] = useState(false);
  const [ajApproved, setAjApproved] = useState(false);
  const [isLoveApproved, setLoveApproved] = useState(false);
  
  const { state: claimState, send: claim } = useContractFunction(StakingContract, 'claim', { transactionName: 'claim' });

  const {account, chainId} = useEthers();

  const zeroAccount = "0x0000000000000000000000000000000000000000";
  const { data, refetch } = useQuery(USER_DATA, {
    variables: { ownerId: account?.toLowerCase() || zeroAccount },
    pollInterval: 5000
  });

  useEffect(() => {
    if (account) {
      setLocked(false);
      refetch();
    } else {
      setLocked(true);
    }
  }, [account, chainId])

  useEffect(() => {
    handleEventUpdate(`claim`, claimState, () => {}, getData)
  }, [claimState])

  useEffect(() => {
    if (data && data.owners.length) {
      console.log(`data`, data)
      const stakedTokens = [...data?.owners[0]?.metropasses?.filter(x => x.isStaked === true),
      ...data?.owners[0]?.passports?.filter(x => x.isStaked === true),
      ...data?.owners[0]?.abnormalJeans?.filter(x => x.isStaked === true)];
      
      const yieldMultiplier = stakedTokens.reduce((prev, curr) => +prev + +curr.currentYield, 0);
      
      setDailyYield(((yieldMultiplier / Config.DIVIDER) * Config.BASE_YIELD).toFixed(0));
      
      setUnstaked(
        data?.owners[0]?.metropasses.filter(x => x.isStaked === false).length +
        data?.owners[0]?.passports.filter(x => x.isStaked === false).length +
        data?.owners[0]?.abnormalJeans.filter(x => x.isStaked === false).length
      )
      
      setStaked(stakedTokens.length);
      setUserTokens(filterUsertokens(data));
      
      if (account) getData();
    }
  }, [data])

  const filterUsertokens = (data) => {
    return {
      staked: {
        metroPasses: filterAndEnrich(data?.owners[0]?.metropasses, true, true, "metroPasses") || [],
        passports: filterAndEnrich(data?.owners[0]?.passports, true, false, "passports") || [],
        abnormalJeans: filterAndEnrich(data?.owners[0]?.abnormalJeans, true, false, "abnormalJeans") || []
      },
      unstaked: {
        metroPasses: filterAndEnrich(data?.owners[0]?.metropasses, false, false, "metroPasses") || [],
        passports: filterAndEnrich(data?.owners[0]?.passports, false, false, "passports") || [],
        abnormalJeans: filterAndEnrich(data?.owners[0]?.abnormalJeans, false, false, "abnormalJeans") || []
      },
    }
  } 

  const filterAndEnrich = (array, param, isMp, collection) => {
    const filtered = array.filter(x => x.isStaked === param)
    return filtered.map(x => {
      let availableAction;
      let nextReroll;
      if (param && isMp) {
        const [canReroll, newRerollValue, currentLevel] = canUpgradeOrReroll(x.tokenType, x.currentYield);
        availableAction = canReroll ? `reroll` : currentLevel < 5 ? `upgrade` : null; 
        nextReroll = newRerollValue;
      }
      return {
        ...x,
        selected: false,
        collection: collection,
        availableAction,
        nextReroll
      }
    })
  }

  const getData = async () => {
    const loveBalance = await getLoveBalance(account);
    const loveAccumulated = await getLoveAccumulated(account);
    setLoveBalance(loveBalance);
    setAccumulatedAmount(loveAccumulated);

    const isMetroPassApproved = await getMetroPassApproved(account);
    setMetroPassApproved(isMetroPassApproved);
    const isPassportApproved = await getPassportApproved(account);
    setPassportApproved(isPassportApproved);
    const isAjApproved = await getAjApproved(account);
    setAjApproved(isAjApproved);
    const isLoveApproved = await getIfLoveApproved(account);
    setLoveApproved(isLoveApproved);
  }

  const claimAccumulated = async () => {
    const gas = await getGasEstimation(StakingContract.estimateGas.claim, [], account)
    if (gas !== null) {
      claim({gasLimit: gas});
      return;
    }
    claim();
  }

  if (locked) {
    return (
      <div className="app_container">
        <Header />
        <LockedScreen />
      </div>
    )
  }

  return (
    <>
      <div className="app_container">
        <Header setCurrentStep={setCurrentStep} />
        <StatsScreen loveBalance={loveBalance} dailyYield={dailyYield}/>

        {currentStep === `home` ? (
          <HomeActive 
            setCurrentStep={setCurrentStep}
            staked={staked}
            unstaked={unstaked}
            accumulatedAmount={accumulatedAmount}
            claim={claimAccumulated}
            getData={getData}
          />
        ): ``}

        {currentStep === `stake` || currentStep === `unstake` ? (
          <TokensList 
            userTokens={userTokens} 
            currentStep={currentStep} 
            setUserTokens={setUserTokens}
            activeCollection={activeCollection}
            setActiveCollection={setActiveCollection}
            metroPassApproved={metroPassApproved}
            passportApproved={passportApproved}
            ajApproved={ajApproved}
            setCurrentStep={setCurrentStep}
            getData={getData}
            account={account}
          />
        ): ``}

        {currentStep === `reroll` ? (
          <RerollScreen 
            userTokens={userTokens}
            accumulated={accumulatedAmount}
            loveBalance={loveBalance}
            isApproved={isLoveApproved}
            setCurrentStep={setCurrentStep}
            getData={getData}
            account={account}
          />
        ): ``}
        
      </div>
      <NotificationContainer/>
    </>
  )
}