import axios from 'axios';
import faunadb, {query as q} from 'faunadb';
import { gql } from "@apollo/client";
import { Config } from '../config';

let client = new faunadb.Client({
  secret: process.env.REACT_APP_FAUNA_KEY,
  domain: 'db.us.fauna.com',
  port: 443,
  scheme: 'https',
})

// export const getSignature = async (address) => {
//   try {
//       address = toChecksumAddress(address);
//       const result = await client.query(
//           q.Call("get_whitelist_signature", address)
//       );
//       let signature = result?.signature;
//       console.log(`signature: ${signature}`);
//       return signature;
//   } catch (err) {
//       console.log(err);
//       return null;
//   }
// }


export const generateStakingSignature = async (collectionAddress, tokenIds) => {
  try {
    const response = await axios({
      url: `${Config.apiLink}/signature`,
      method: 'post',
      data: {
        collectionAddress,
        tokenIds
      }
    });
    return response.data;
  } catch (err) {
    console.error(err);
  }
}


export const USER_DATA = gql`
query User($ownerId: String!) {
  owners(where: {id: $ownerId}) {
    metropasses(first: 400) {
      id
      isStaked
      currentYield
      tokenType
    }
    passports(first: 400) {
      id
      isStaked
      currentYield
    }
    passports(first: 400) {
      id
      isStaked
      currentYield
    }
    abnormalJeans(first: 400) {
      id
      isStaked
      currentYield
    }
  }
}
`;
