import { NotificationManager } from "react-notifications";
import {Config} from "../config";

export const metroPassAddress = Config.metroPassAddress;
export const passportAddress = Config.passportAddress;
export const ajAddress = Config.ajAddress;

export const COLLECTION_TO_ADDRESS = {
  metroPasses: metroPassAddress,
  passports: passportAddress,
  abnormalJeans: ajAddress
}

export const MAX_APPROVE = "100000000000000000000000000000";

export const METROPASS_LEVELS = [
  [[1250, 1380], [1880, 2070], [2190, 2420], [2500, 2760], [3130, 3450]],
  [[1380, 1500], [2070, 2250], [2420, 2630], [2760, 3000], [3450, 3750]],
  [[1630, 1750], [2450, 2630], [2850, 3060], [3260, 3500], [4080, 4380]],
  [[1750, 1880], [2630, 2820], [3060, 3290], [3500, 3760], [4380, 4700]],
  [[1880, 2000], [2820, 3000], [3290, 3500], [3760, 4000], [4700, 5000]],
  [[2000, 2130], [3000, 3200], [3500, 3730], [4000, 4260], [5000, 5330]],
  [[2130, 2250], [3200, 3380], [3730, 3940], [4260, 4500], [5330, 5630]],
  [[2380, 2500], [3570, 3750], [4170, 4380], [4760, 5000], [5950, 6250]],
  [[2500, 2630], [3750, 3950], [4380, 4600], [5000, 5260], [6250, 6580]],
  [[2880, 3000], [4320, 4500], [5040, 5250], [5760, 6000], [7200, 7500]],
  [[3000, 3130], [4500, 4700], [5250, 5480], [6000, 6260], [7500, 7830]],
  [[3130, 3250], [4700, 4880], [5480, 5690], [6260, 6500], [7830, 8130]],
  [[3250, 3380], [4880, 5070], [5690, 5920], [6500, 6760], [8130, 8450]],
];


export const canUpgradeOrReroll = (tokenType, currentMultiplier) => {
  const rerollStep = 500;

  let canReroll = false;
  let newRerollValue = 0;
  let currentLevel = 0;

  for (let i = 0; i < METROPASS_LEVELS[tokenType].length; i++) {
    if (
      currentMultiplier >= METROPASS_LEVELS[tokenType][i][0] 
      && currentMultiplier < METROPASS_LEVELS[tokenType][i][1]
    ) {
      if (currentMultiplier + rerollStep >= METROPASS_LEVELS[tokenType][i][1]) {
        canReroll = true;
        newRerollValue = METROPASS_LEVELS[tokenType][i][1];
      } else {
        canReroll = true;
        newRerollValue = currentMultiplier + rerollStep;
      }
    }
    if (currentMultiplier === METROPASS_LEVELS[tokenType][i][1]) currentLevel = i + 1;
  }

  return [canReroll, newRerollValue, currentLevel];
}


export const handleEventUpdate = (type, state, serviceFunction, onComplete) => {
  console.log(state)
  const status = state.status;
  if (type === `approve`) {
    // eslint-disable-next-line default-case
    switch (status) {
      case 'Mining':
        serviceFunction(true);
        NotificationManager.info(`Approve transaction is pending!`);
        break;
      case 'Success': 
        serviceFunction(false);
        onComplete();
        NotificationManager.success(`Approve transaction confirmed!`);
        break;
      case 'Exception': 
        if (state.errorMessage.includes('User denied transaction')) {
          break;
        }
        NotificationManager.warning(`Something went wrong!`);
        break;
      }
  }
  if (type === `claim`) {
    // eslint-disable-next-line default-case
    switch (status) {
      case 'Mining':
        NotificationManager.info(`Claim is pending!`);
        break;
      case 'Success': 
        onComplete();
        NotificationManager.success(`You succennfully claimed your reward!`);
        break;
      case 'Exception': 
        if (state.errorMessage.includes('User denied transaction')) {
          break;
        }
        NotificationManager.warning(`Something went wrong!`);
        break;
      }
  }
  if (type === `staking`) {
    // eslint-disable-next-line default-case
    switch (status) {
      case 'Mining':
        serviceFunction(true);
        onComplete([])
        NotificationManager.info(`Your transaction is pending!`);
        break;
      case 'Success': 
        serviceFunction(false);
        NotificationManager.success(`Transaction confirmed!`);
        break;
      case 'Exception': 
        if (state.errorMessage.includes('User denied transaction')) {
          break;
        }
        NotificationManager.warning(`Something went wrong! Try again!`);
        break;
      }
  }
  if (type === `reroll`) {
    // eslint-disable-next-line default-case
    switch (status) {
      case 'Mining':
        NotificationManager.info(`Your rerolling is in progress!`);
        break;
      case 'Success': 
        const event = state.receipt.events.filter(x => x.args);
        console.log(event)
        if (event[0].args.success) {
          NotificationManager.success(`Transaction confirmed! Reroll successfull!`);
          break;
        }
        onComplete()
        NotificationManager.success(`Transaction confirmed! No change to your multiplier!`);
        break;
      case 'Exception': 
        if (state.errorMessage.includes('User denied transaction')) {
          break;
        }
        NotificationManager.warning(`Something went wrong!`);
        break;
      case 'Error': 
        NotificationManager.warning(`Something went wrong!`);
        break;
      }
  }
  if (type === `upgrade`) {
    // eslint-disable-next-line default-case
    switch (status) {
      case 'Mining':
        NotificationManager.info(`Your upgrade is in progress!`);
        break;
      case 'Success': 
        NotificationManager.success(`Transaction confirmed! Your token leveled up!`);
        break;
        onComplete()
      case 'Exception': 
        if (state.errorMessage.includes('User denied transaction')) {
          break;
        }
        NotificationManager.warning(`Something went wrong!`);
        break;
      case 'Error': 
        NotificationManager.warning(`Something went wrong!`);
        break;
      }
  }
}


export const getGasEstimation = async (tx, args, account) => {
  try {
    const estimation = await tx(...args, {from: account});
    console.log(estimation.toString())
    return String(Math.round(estimation.toString() * 1.35))
  } catch (e) {
    return null
  }
}  