import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import logo from '../assets/images/logo.png';

const Header = ({
  setCurrentStep
}) => {
  return (
    <Container fluid={true} style={{textAlign: `center`}} className="mb-40">
      <Row>
        <Col>
        <img src={logo} alt={"mint page title"} className="image__title" onClick={() => setCurrentStep(`home`)} style={{
          cursor: `pointer`
        }}/>
        </Col>
      </Row>
    </Container>
  )
}

export default Header