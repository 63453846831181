import React from 'react'

const Button = ({children, width = `100%`, background = `#FFF500`, onClick, style, className}) => {
  return (
    <div className={`button ${className}`} style={{
      ...style,
      maxWidth: width,
      background
    }} onClick={onClick}>{children}</div>
  )
}

export default Button