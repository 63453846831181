import React from 'react'
import { useContractFunction, useEthers } from "@usedapp/core";
import { Container, Row, Col } from "react-bootstrap";
import { NotificationManager } from 'react-notifications';

import Button from './Button'
import coin from '../assets/images/icon.png';

const LockedScreen = () => {
  
  const {activateBrowserWallet, account, chainId} = useEthers();

  const onError = () => {
    NotificationManager.warning('Metamask not installed.', `Woops!`, 3000);
  }

  const activateWallet = async () => {
    if (!account) {
      activateBrowserWallet(onError);
    }
  }

  return (
    <Container fluid={true} style={{background: `rgba(230, 0, 0, 0.7)`, paddingTop: `50px`, paddingBottom: `50px`, justifyContent: `center`}}>
      <Container>
        <Row style={{alignItems: `center`}} >
          <Col md={{ offset: 2, span: 4 }} xs={{ span: 12, offset: 0 }} className="justify-content-center">
            <img src={coin} alt={"mint page title"} className="coin__image" />
          </Col>
          <Col md="4" xs="12">
            <Row className="text__normal mb-20"><Col>Connect your wallet to see your stats and assets</Col></Row>
            <Row className="justify-content-center"><Button width="300px" onClick={activateWallet}>Connect</Button></Row>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default LockedScreen