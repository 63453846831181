import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import Button from './Button'

const StatsScreen = ({
  loveBalance,
  dailyYield
}) => {
  return (
    <Container fluid={true} className="mb-40" style={{background: `rgba(230, 0, 0, 0.7)`}}>
      <Container style={{padding: `50px 0px`}}>
        <Row style={{alignItems: `center`, textAlign: `center`}}>
          <Col className="yellow-title mb-40">YOUR LOVELESS CITY STATS</Col>
        </Row>
        <Row style={{alignItems: `center`}}>
          <Col md={{ span: 4, offset: 2 }} xs={{ span: 12, offset: 0 }}>
            <Row className="text__normal text-center"><Col>$LOVE Balance</Col></Row>
            <Row className="yellow-title text-center"><Col>{loveBalance} $LOVE</Col></Row>
          </Col>
          <Col md={{ span: 4}} xs={{ span: 12}}>
            <Row className="text__normal text-center"><Col>Current Daily Yield</Col></Row>
            <Row className="yellow-title text-center"><Col>{dailyYield} $LOVE</Col></Row>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default StatsScreen