export const Config = {
  metroPassAddress: process.env.REACT_APP_METRO_PASS,
  passportAddress: process.env.REACT_APP_PASSPORT,
  ajAddress: process.env.REACT_APP_ABNORMAL_JEAN,
  loveAddress: process.env.REACT_APP_LOVE,
  stakingAddress: process.env.REACT_APP_STAKING,
  infuraUri: process.env.REACT_APP_INFURA,
  targetChain: 1,
  subgraph: process.env.REACT_APP_SUBGRAPH_URI,
  apiLink: process.env.REACT_APP_SERVER_API,
  UPGRADE_PRICE: 1500,
  REROLL_PRICE: 750,
  DIVIDER: 1000,
  BASE_YIELD: 100
}