import MintPage from "./components/MintPage"
import { Config } from "./config"
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: Config.subgraph,
  cache: new InMemoryCache()
});

function App() {
  return (
    <div className="App"> 
      <ApolloProvider client={client}>
        <MintPage />
      </ApolloProvider>
    </div>
  );
}

export default App;
