import React from 'react'
import { Col } from "react-bootstrap";

const TokenCard = ({
  isSelected,
  img,
  onClick
}) => {
  return (
    <Col md="3" xs="6" style={{
      width: '140px',
      height: '140px',
      background: isSelected ? 'rgb(22 165 11 / 50%)' : 'rgb(165 12 11 / 50%)',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: `10px`,
      cursor: `pointer`
    }} onClick={onClick}><img src={img} className="stakingImage"/></Col>
  )
}

export default TokenCard