import {useEffect, useMemo, useState} from 'react'
import { Contract } from '@ethersproject/contracts'
import { useContractFunction, useEthers } from "@usedapp/core";
import { utils, ethers } from 'ethers'
import { Container, Row, Col } from "react-bootstrap";
import Button from './Button'
import TokenCard from './TokenCard';

import {stakingAbi} from '../assets/blockchain/Staking'
import {erc721Abi} from '../assets/blockchain/ERC721'
import {Config} from '../config'

import {COLLECTION_TO_ADDRESS, handleEventUpdate, getGasEstimation} from '../plugins/utils'
import {generateStakingSignature} from '../plugins/model'

const provider = ethers.getDefaultProvider(Config.infuraUri);

const StakingAbi = new utils.Interface(stakingAbi); 
const StakingContract = new Contract(Config.stakingAddress, StakingAbi, provider);

const ERC721Abi = new utils.Interface(erc721Abi); 
const metroPassContract = new Contract(Config.metroPassAddress, ERC721Abi);
const passportContract = new Contract(Config.passportAddress, ERC721Abi);
const ajContract = new Contract(Config.ajAddress, ERC721Abi);

const TokensList = ({
  currentStep,
  userTokens,
  setUserTokens,
  activeCollection,
  setActiveCollection,
  metroPassApproved,
  passportApproved,
  ajApproved,
  setCurrentStep,
  getData,
  account
}) => {
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [isApproving, setIsApproving] = useState(false);

  const { state: depositState, send: deposit } = useContractFunction(StakingContract, 'deposit', { transactionName: 'deposit' });
  const { state: withdrawState, send: withdraw } = useContractFunction(StakingContract, 'withdraw', { transactionName: 'withdraw' });
  const { state: approveMetroPassState, send: approveMetroPass } = useContractFunction(metroPassContract, 'setApprovalForAll', { transactionName: 'approve' });
  const { state: approvePassportState, send: approvePassport } = useContractFunction(passportContract, 'setApprovalForAll', { transactionName: 'approve' });
  const { state: approveAjState, send: approveAj } = useContractFunction(ajContract, 'setApprovalForAll', { transactionName: 'approve' });

  const stakeIdentifier = currentStep === `stake` ? `unstaked` : `staked`;

  useEffect(() => {
    handleEventUpdate(`approve`, approveMetroPassState, setIsApproving, getData)
  }, [approveMetroPassState])
  useEffect(() => {
    handleEventUpdate(`approve`, approvePassportState, setIsApproving, getData)
  }, [approvePassportState])
  useEffect(() => {
    handleEventUpdate(`approve`, approveAjState, setIsApproving, getData)
  }, [approveAjState])
  useEffect(() => {
    handleEventUpdate(`staking`, withdrawState, setIsApproving, () => resetSelection(activeCollection))
  }, [withdrawState])
  useEffect(() => {
    handleEventUpdate(`staking`, depositState, setIsApproving, () => resetSelection(activeCollection))
  }, [depositState])

  const triggerTokenStatus = (token) => {
    if (isApproving) return;
    const stakeIdentifier = currentStep === `stake` ? `unstaked` : `staked`;

    if (activeCollection !== token.collection) {
      if (activeCollection) resetSelection(activeCollection)
      setActiveCollection(token.collection)
    }
    
    setUserTokens(prev => {
      return {
        ...prev,
        [stakeIdentifier]: {
          ...prev[stakeIdentifier],
          [token.collection]: prev[stakeIdentifier][token.collection].map(x => {
            if (x.id === token.id) {
              return {
                ...x,
                selected: !x.selected
              }
            }
            return {
              ...x
            }
          })
        }
      }
    })

    truggerSelectedId(token.id)
  }

  const truggerSelectedId = (id) => {
    const isToAdd = !selectedTokens.includes(id);
    setSelectedTokens(prev => isToAdd ? [...prev, id] : prev.filter(y => y !== id));
  }

  const resetSelection = (activeCollection) => {
    const stakeIdentifier = currentStep === `stake` ? `unstaked` : `staked`;
    setSelectedTokens([]);
    setUserTokens(prev => {
      return {
        ...prev,
        [stakeIdentifier]: {
          ...prev[stakeIdentifier],
          [activeCollection]: prev[stakeIdentifier][activeCollection].map(x => {
            return {
              ...x,
              selected: false
            }
          })
        }
      }
    })
  }

  const isApproved = useMemo(() => {
    if (activeCollection === `metroPasses`) return metroPassApproved;
    if (activeCollection === `passports`) return passportApproved;
    if (activeCollection === `abnormalJeans`) return ajApproved;
  }, [activeCollection, metroPassApproved, passportApproved, ajApproved])

  const triggerTransaction = async () => {
    if (!selectedTokens.length) return;

    const collectionAddress = COLLECTION_TO_ADDRESS[activeCollection];
    
    if (currentStep === 'stake') {
      const stakingData = await generateStakingSignature(collectionAddress, selectedTokens);
      console.log(collectionAddress, selectedTokens, stakingData.multipliers, stakingData.tokenTypes, stakingData.signature)
      const gas = await getGasEstimation(StakingContract.estimateGas.deposit, [collectionAddress, selectedTokens, stakingData.multipliers, stakingData.tokenTypes, stakingData.signature], account)
      if (gas !== null) {
        deposit(collectionAddress, selectedTokens, stakingData.multipliers, stakingData.tokenTypes, stakingData.signature, {gasLimit: gas});
        return;
      }
      deposit(collectionAddress, selectedTokens, stakingData.multipliers, stakingData.tokenTypes, stakingData.signature)
      return;
    }

    const gas = await getGasEstimation(StakingContract.estimateGas.withdraw, [collectionAddress, selectedTokens], account)
    if (gas !== null) {
      withdraw(collectionAddress, selectedTokens, {gasLimit: gas});
      return;
    }
    withdraw(collectionAddress, selectedTokens);
  }

  const approve = () => {
    if (!selectedTokens.length) return;
    if (activeCollection === `metroPasses`) {
      approveMetroPass(Config.stakingAddress, true);
      return;
    };
    if (activeCollection === `passports`) {
      approvePassport(Config.stakingAddress, true);
      return;
    };
    if (activeCollection === `abnormalJeans`) {
      approveAj(Config.stakingAddress, true);
      return;
    };
  }

  return (
    <Container>
      <Row>
        <Col md={{offset: 2, span: 8}} xs={{offset: 0, span: 12}} className="mobile-center">
          <Button width='250px' className={"mb-20"} onClick={() => setCurrentStep(`home`)}>Go Back</Button>
          <Col xs={12}><div className='text__normal mb-20'>You can {currentStep} multiple NFT's at one time, however, each collection will need to be staked separately</div></Col>
          <Row style={{alignItems: `center`}} className="mb-20">
            <Col className="white-title">MetroPasses</Col>
          </Row>
          <Row className='mb-20 mobile-center'>
            {
              userTokens[stakeIdentifier].metroPasses.length ? (
                <>
                {userTokens[stakeIdentifier].metroPasses.map(x => {
                  return (
                    <TokenCard 
                      key={x.id}
                      img={`https://sea-lion-app-2tf22.ondigitalocean.app/metro-pass/${x.id}`}
                      isSelected={x.selected}
                      onClick={() => triggerTokenStatus(x)}
                    />
                  )
                })}
                </>
              ) : <div className='text__normal mb-20'>No MetroPasses {currentStep === `stake` ? `Owned` : `Staked`}</div>
            }
          </Row>

          <Row style={{alignItems: `center`}} className="mb-20">
            <Col className="white-title">Passports</Col>
          </Row>
          <Row className='mb-20 mobile-center'>
            {
              userTokens[stakeIdentifier].passports.length ? (
                <>
                  {userTokens[stakeIdentifier].passports.map(x => {
                    return (
                      <TokenCard 
                        key={x.id}
                        img={`https://sea-lion-app-2tf22.ondigitalocean.app/passport/${x.id}`}
                        isSelected={x.selected}
                        onClick={() => triggerTokenStatus(x)}
                      />
                    )
                  })}
                </>
              ) : <div className='text__normal mb-20'>No Passports {currentStep === `stake` ? `Owned` : `Staked`}</div>
            }
          </Row>

          <Row style={{alignItems: `center`}} className="mb-20">
            <Col className="white-title">AbnormalJean</Col>
          </Row>
          <Row className='mb-20 mobile-center'>
            {
              userTokens[stakeIdentifier].abnormalJeans.length ? (
                <>
                  {userTokens[stakeIdentifier].abnormalJeans.map(x => {
                    return (
                      <TokenCard 
                        key={x.id}
                        img={`https://sea-lion-app-2tf22.ondigitalocean.app/aj/${x.id}`}
                        isSelected={x.selected}
                        onClick={() => triggerTokenStatus(x)}
                      />
                    )
                  })}
                </>
              ) : <div className='text__normal mb-20'>No AbnormalJeans {currentStep === `stake` ? `Owned` : `Staked`}</div>
            }
          </Row>
        </Col>
        <Button 
        style={{
          margin: `0 auto`,
          marginBottom: `40px`
        }}
        onClick={
          isApproving ? () => {}
            :currentStep === `stake` ? 
            isApproved ? triggerTransaction : approve
            : triggerTransaction
        } width={`300px`}>
          {
            isApproving ? `Pending...`
            : currentStep === `stake` ? 
            isApproved ? `Confirm stake` : `Approve Token Transfer` 
            : `Confirm unstake`
          }
        </Button>
      </Row>
    </Container>
  )
}

export default TokensList